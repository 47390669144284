import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
import { Suspense } from "react";
import Spinner from "../paymentgateway/components/loading spiner";

import LandingPageUdin from "../LandingPageUdin/Pages";
import LandingMerchantBisnis from "../LandingPageUdin/Components/merchantBisnis";
import NotFoundPage from "../LandingPageUdin/Components/notFound";
import Verivikasi from "../LandingPageUdin/Pages/verifikasi";
const DashbordUtama = lazy(() => import("../paymentgateway/pages/landingpagepayment"));
const Register = lazy(() => import("../paymentgateway/pages/registrasi"));
const Login = lazy(() => import("../paymentgateway/pages/login"));
const Beranda = lazy(() => import("../paymentgateway/pages/beranda"));
const BerandaSandbox = lazy(() => import("../paymentgateway/pages/beranda sandbox"));
const Transaksi = lazy(() => import("../paymentgateway/pages/transaksi"));
const TransaksiSandbox = lazy(() => import("../paymentgateway/pages/transaksi sandbox"));
const TransaksiProyek = lazy(() => import("../paymentgateway/pages/transaksi-proyek"));
const TransaksiProyekSandbox = lazy(() => import("../paymentgateway/pages/transaksi-proyek sandbox"));
const Saldo = lazy(() => import("../paymentgateway/pages/saldo"));
const SaldoSandbox = lazy(() => import("../paymentgateway/pages/saldo sandbox"));
const ChangeNorek = lazy(() => import("../paymentgateway/pages/saldo/gantinorek/tambahnorek"));
const AllData2 = lazy(() => import("../paymentgateway/pages/alldata"));
const Profile = lazy(() => import("../paymentgateway/pages/profile"));
const IpWhitlist = lazy(() => import("../paymentgateway/pages/ipwhitlist"));
const IpWhitlistSandbox = lazy(() => import("../paymentgateway/pages/ipwhitlist sandbox"));
const Pin = lazy(() => import("../paymentgateway/pages/pin"));
const AccsesKey = lazy(() => import("../paymentgateway/pages/accses-key"));
const Logout = lazy(() => import("../paymentgateway/pages/logout"));
const SummaryPerusahaan = lazy(() => import("../paymentgateway/pages/alldata/dataperusahaan/summary"));
const SummaryIndividu = lazy(() => import("../paymentgateway/pages/alldata/dataindividu/summary"));
const DashboardIndividu = lazy(() => import("./DahsboardIndividu"));
const ErrorPage = lazy(() => import("../LandingPageUdin/Components/pageError"));
const Proyek = lazy(() => import("../paymentgateway/pages/proyek"));
const AddProject = lazy(() => import("../paymentgateway/pages/proyek/addProject"));
const BankTransferOptions = lazy(() => import("../paymentgateway/pages/proyek/pembayaran/bank"));
const BankVerification = lazy(() => import("../paymentgateway/pages/proyek/pembayaran/bca"));
const QrisVerification = lazy(() => import("../paymentgateway/pages/proyek/pembayaran/qris"));
const BankDetail = lazy(() => import("../paymentgateway/pages/proyek/pembayaran/getPengajuan"));
const EditProyek = lazy(() => import("../paymentgateway/pages/proyek/editProject"));
const ProyekAllQris = lazy(() => import("../paymentgateway/pages/proyekQrisValid/index"));
const ProyekQris = lazy(() => import("../paymentgateway/pages/proyekQrisValid/index"));
const ProyekQrisDetail = lazy(() => import("../paymentgateway/pages/proyek-qris/detail_qris"));

export const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Spinner />}>
        <DashbordUtama />
      </Suspense>
    ),
    errorElement: <NotFoundPage />,
  },

  {
    path: "register",
    element: (
      <Suspense fallback={<Spinner />}>
        <Register />
      </Suspense>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: "login",
    element: (
      <Suspense fallback={<Spinner />}>
        <Login />
      </Suspense>
    ),
    errorElement: <NotFoundPage />,
  },

  // ROUTER PRODUCTION
  {
    path: "me/",
    element: (
      <Suspense fallback={<Spinner />}>
        <DashboardIndividu />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <NotFoundPage />, errorElement: <ErrorPage /> },
      {
        path: "beranda",
        element: <Beranda />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "beranda-sandbox",
        element: <BerandaSandbox />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "transaksi",
        element: <Transaksi />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "transaksi-sandbox",
        element: <TransaksiSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "transpro",
        element: <TransaksiProyek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "transpro-sandbox",
        element: <TransaksiProyekSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "saldo",
        element: <Saldo />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "saldo-sandbox",
        element: <SaldoSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "saldo/changenorek",
        element: <ChangeNorek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek",
        element: <Proyek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/add",
        element: <AddProject />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/edit",
        element: <EditProyek />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/:id",
        element: <BankTransferOptions />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/:id/:id/",
        element: <BankVerification />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/:id/:id/qris",
        element: <QrisVerification />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek/pembayaran/detail/:id",
        element: <BankDetail />,
        errorElement: <NotFoundPage />,
      },

      // QRIS INDIVIDU

      {
        path: "validQris",
        element: <ProyekAllQris />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek-qris",
        element: <ProyekQris />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "proyek-qris-detail",
        element: <ProyekQrisDetail />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "data",
        element: <AllData2 />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "summary-perusahaan",
        element: <SummaryPerusahaan />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "summary-individu",
        element: <SummaryIndividu />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "profile",
        element: <Profile />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "ip-whitlist",
        element: <IpWhitlist />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "ip-whitlist-sandbox",
        element: <IpWhitlistSandbox />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "pin",
        element: <Pin />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "accses-key",
        element: <AccsesKey />,
        errorElement: <NotFoundPage />,
      },

      {
        path: "logout",
        element: <Logout />,
        errorElement: <NotFoundPage />,
      },
    ],
  },

  // Route UdinPay

  {
    path: "/udin-payment",
    element: <LandingPageUdin />,
    errorElement: <NotFoundPage />,
  },

  {
    path: "/udin-payment/verivikasi",
    element: <Verivikasi />,
    errorElement: <NotFoundPage />,
  },

  {
    path: "/udin-payment-Merchant-Bisnis",
    element: <LandingMerchantBisnis />,
    errorElement: <NotFoundPage />,
  },
]);
