import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../../services/axiosInstance";
import { UserResponse } from "../interface/profile.interface"; // Sesuaikan path jika diperlukan

interface ProfileState {
  user: UserResponse["users"] | null; // Data user atau null jika belum diambil
  status: "idle" | "loading" | "succeeded" | "failed";
  loading: boolean;
  error: string | null;
}

const initialState: ProfileState = {
  user: null,
  status: "idle",
  loading: false,
  error: null,
};

export const fetchUserProfile = createAsyncThunk<UserResponse, void>("profile/fetchUserProfile", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/get-profile`);
    return response.data; // Pastikan ini sesuai dengan struktur `UserResponse`
  } catch (error: any) {
    console.error("Error fetching profile:", error);
    // Kirim pesan error spesifik untuk 404
    if (error.response && error.response.status === 404) {
      return rejectWithValue({ status: 404, message: "Not Found" });
    }
    throw error;
  }
});

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action: PayloadAction<UserResponse>) => {
        state.loading = false;
        state.status = "succeeded";
        state.user = action.payload.users; // Set data user dari respons
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.status = "failed";
        if (action.payload && (action.payload as any).status === 404) {
          // Hapus semua data di localStorage dan arahkan ke /me/login
          localStorage.clear();
          // window.location.href = "/me/login";
        } else {
          state.error = action.error.message || "Failed to fetch user profile";
        }
        console.error("Error fetching user profile:", action.error.message);
      });
  },
});

export default profileSlice.reducer;
